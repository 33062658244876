import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Your Page Title</title>
        <meta name="description" content="A brief description of your landing page" />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="A brief description of your landing page" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://yourwebsite.com" />
      </Helmet>
      <Header />
      <Hero />
      <Features />
      <Testimonials />
      <Footer />
    </div>
  );
}

const Header = () => (
  <header className="Header">
    <nav>
      <ul>
        <li><a href="#home">Home</a></li>
        <li><a href="#features">Features</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </nav>
  </header>
);

const Hero = () => (
  <section id="home" className="Hero">
    <h1>Welcome to Our Landing Page</h1>
    <p>We offer amazing products and services for you.</p>
    <a href="#contact" className="cta-button">Get Started</a>
  </section>
);

const Features = () => (
  <section id="features" className="Features">
    <h2>Features</h2>
    <div className="features-grid">
      <div className="feature-item">
        <h3>Feature 1</h3>
        <p>Description of feature 1.</p>
      </div>
      <div className="feature-item">
        <h3>Feature 2</h3>
        <p>Description of feature 2.</p>
      </div>
      <div className="feature-item">
        <h3>Feature 3</h3>
        <p>Description of feature 3.</p>
      </div>
    </div>
  </section>
);

const Testimonials = () => (
  <section id="testimonials" className="Testimonials">
    <h2>What Our Clients Say</h2>
    <div className="testimonials-grid">
      <div className="testimonial-item">
        <p>"This is the best service I've ever used!"</p>
        <cite>- Customer 1</cite>
      </div>
      <div className="testimonial-item">
        <p>"Highly recommend to everyone."</p>
        <cite>- Customer 2</cite>
      </div>
    </div>
  </section>
);

const Footer = () => (
  <footer id="contact" className="Footer">
    <p>Contact us at: email@example.com</p>
    <p>&copy; 2024 Your Company</p>
  </footer>
);

export default App;
